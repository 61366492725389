import update from 'immutability-helper'
import { EditRegular, Navigation24Regular, SaveRegular, SearchRegular } from "@fluentui/react-icons";
import { Accordion, AccordionHeader, AccordionItem, Button, Card, CardHeader, Input, Skeleton, SkeletonItem } from "@fluentui/react-components";
import TATeamCard from './TeamCard';
import './Category.css'
import { useCallback, useContext, useEffect, useState } from "react";
import { useDrop } from 'react-dnd';
import { TAConfigContext } from '../../../TeamsNavigator';
import { TANothingFound, TANothingFoundSizeMedium } from '../../../components/NothingFound';
import TAEditCategory from './EditCategory';
import TARefreshTeamsButton from './RefreshTeamsButton';
import useCheckMobileScreen from '../../../utils/Hooks';

export default function TACategory({ isLoading, categoryId, editMode, setEditMode, disabled, setDialogOpen }) {
    const isMobile = useCheckMobileScreen();
    const { config, setConfig } = useContext(TAConfigContext);
    const [teams, setTeams] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, updateSelectedCategory] = useState(null);

    // A list of the teams in the category ({ id, channels })
    const [taCategoryTeams, setTACategoryTeams] = useState([]);

    // A list of the team in the category ( MS Teams object )
    const [msCategoryTeams, setMSCategoryTeams] = useState([]);

    // The search query to filter the teams
    const [searchQuery, setSearchQuery] = useState("");

    // Filtered teams
    const [filteredTeams, setFilteredTeams] = useState([]);

    const saveConfig = useCallback((newCategories) => {
        console.log('[Category] saving config');
        setConfig({ ...config, taConfig: { ...config.taConfig, categories: [...newCategories] } });
    }, [setConfig, config]);

    // The config file changed
    useEffect(() => {
        console.log('[Category] config changed', config);
        if (!config || !config.taConfig || !config.taConfig.categories) return;
        setCategories(config.taConfig.categories);
        setTeams(config.msTeamsData.teams);
    }, [config])

    // Set the selected category when we click a category in the sidepanel
    useEffect(() => {
        console.log("[Category] categoryId changed")
        if (!categories || categories.length <= 0) return;

        const newSelectedCategory = categories.find(c => c.id === categoryId);
        if (!newSelectedCategory) return;

        updateSelectedCategory(newSelectedCategory);
        setTACategoryTeams([...newSelectedCategory.teams]);
        setMSCategoryTeams([...teams.filter(t => newSelectedCategory.teams.map(tt => tt.id).includes(t.id))]);
        setSearchQuery("");
    }, [categoryId, categories, teams])

    // Apply changes to a category
    const updateCategory = useCallback((taCategoryTeams) => {
        if (!categories || categories.length <= 0 || !selectedCategory) return;

        const idx = categories.findIndex(c => c.id === selectedCategory.id);
        if (idx === -1) return;

        categories[idx] = { ...selectedCategory, teams: taCategoryTeams };
		// saveConfig([...categories])
    }, [categories, selectedCategory]);

    useEffect(() => {
        setFilteredTeams(
            msCategoryTeams
                .filter(t => t.name.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    }, [msCategoryTeams, searchQuery, taCategoryTeams]);

    // (OnChange listener) Store updated category
    useEffect(() => {
        console.log('taCategoryTeams updated')
        updateCategory(taCategoryTeams)
        setMSCategoryTeams(taCategoryTeams.map(t => teams.find(t_ => t.id === t_.id)));
    }, [taCategoryTeams, updateCategory, teams]);

    // This method is called when we drop a team from the sidebar
    // eslint-disable-next-line
    const [{ }, drop] = useDrop(() => {
        return {
            'accept': 'team',
            drop(item, monitor) {
                if (!item.copyMode)
                    return;

                let channels = [];
                if (teams && item.team)
                    channels = teams.find(t => t.id === item.team).channels.map(c => c.id);

                setTACategoryTeams((prevTeams) =>
                    update((prevTeams), {
                        $push: [
                            {
                                id: item.team,
                                channels: channels
                            }
                        ]
                    })
                );
            }
        }
    }, [taCategoryTeams]);


	const addTeam = useCallback((teamId) => {
        let channels = [];
        if (teams && teamId)
            channels = teams.find(t => t.id === teamId).channels.map(c => c.id);

        setTACategoryTeams((prevTeams) =>
            update((prevTeams), {
                $push: [
                    {
                        id: teamId,
                        channels: channels
                    }
                ]
            })
        );
	}, [teams])

    // This method is called when we drag and drop to reorder the teams.
    const moveAction = useCallback((dragIndex, hoverIndex, copyMode) => {
        console.log("[Category] move action", dragIndex, hoverIndex, copyMode)
        if (copyMode)
            return;

        setTACategoryTeams((prevTeams) =>
            update(prevTeams, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevTeams[dragIndex]],
                ],
            }),
        );
    }, []);

    // This method is called when we remove a team.
    const removeAction = useCallback((team) => {
        console.log('[Category] removeAction')

        const correctIndex = taCategoryTeams.findIndex(t => team.id === t.id);
        if (correctIndex === -1) return;

        setTACategoryTeams((prevTeams) =>
            update(prevTeams, {
                $splice: [
                    [correctIndex, 1]
                ]
            })
        );
    }, [taCategoryTeams])

    if (isLoading || !selectedCategory) {
        return (
            <div className="tab-body">
                <div className="fx-bar">
                    <div className="fx-bar-left" style={{ flex: '1', paddingRight: '50px' }}>
                        <Skeleton
                            appearance="translucent">
                            <SkeletonItem size={24} />
                        </Skeleton>
                    </div>
                    <div className="fx-bar-right" style={{ width: '200px' }}>
                        <Skeleton
                            appearance="translucent">
                            <SkeletonItem size={24} />
                        </Skeleton>
                    </div>
                </div>
                <div className='search-bar-container'>
                    <Input
                        appearance='filled-lighter'
                        size="medium"
                        contentAfter={<SearchRegular />}
                        disabled={true}
                        placeholder="Search teams" />
                </div>
                <div className="tab-content">
                    {[1, 2, 3, 4, 5].map(() =>
                        <div style={{ padding: '10px' }}>
                            <Card >
                                <CardHeader
                                    image={<Skeleton appearance='translucent'><SkeletonItem shape='circle' size={36} /></Skeleton>}
                                    header={
                                        <div style={{ width: "240px" }}>
                                            <Skeleton appearance='translucent'><SkeletonItem size={24} /></Skeleton>
                                        </div>
                                    }
                                />
                                <Accordion collapsible="true">
                                    <AccordionItem value="Pinned Channels" disabled={true}>
                                        <AccordionHeader expandIconPosition="end">
                                            <div style={{ width: "240px" }}>
                                                <Skeleton appearance='translucent'><SkeletonItem size={12} /></Skeleton>
                                            </div>
                                        </AccordionHeader>
                                    </AccordionItem>
                                </Accordion>
                            </Card>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    if (! selectedCategory || !selectedCategory.name || searchQuery === null || searchQuery === undefined) return <></>;

    return (
        <>
            <div className="tab-body">
                <div className="fx-bar">
                    <div className="fx-bar-left">
                        {
                            (isMobile)
                                ? <Button onClick={() => setDialogOpen(true)} style={{backgroundColor: 'transparent', border: 'none', paddingRight: '10px'}} icon={<Navigation24Regular />} />
                                : null
                        }
                        {
                            (!selectedCategory.isFavoriteCategory && !selectedCategory.isAllTeamsCategory) && editMode
                                ? <Input
                                    size="large"
                                    appearance="underline"
                                    contentEditable="true"
                                    contentAfter={<EditRegular />}
                                    value={selectedCategory.name}
                                    onChange={(e, data) => {
                                        const tmp = { ...selectedCategory }
                                        tmp.name = data.value;

                                        if (!tmp || ! tmp.name) return;
                                        updateSelectedCategory(tmp);
                                    }} />
                                : <h3>{selectedCategory.name}</h3>
                        }
                    </div>
                    <div className="fx-bar-right">
                        <TARefreshTeamsButton disabled={disabled}  />
                        {
                            (editMode && !selectedCategory.isAllTeamsCategory) ?
                                <Button icon={<SaveRegular />}
                                    onClick={() => {
                                        saveConfig(categories);
                                        setEditMode(false)
                                        setSearchQuery("")
                                    }}
                                    disabled={disabled}
                                    style={{ marginLeft: '10px' }}>
                                    Save
                                </Button>
                                :
                                (!editMode && !selectedCategory.isAllTeamsCategory && !isMobile)
                                    ? <Button 
                                        icon={<EditRegular />} 
                                        disabled={disabled}
                                        onClick={() => {
                                            setEditMode(true)
                                            setSearchQuery("");
                                        }} 
                                        style={{ marginLeft: '10px' }}>
                                            Edit Category
                                        </Button>
                                    : <></>
                        }

                    </div>
                </div>
                <div className='search-bar-container'>
                    <Input
                        disabled={disabled}
                        appearance='filled-lighter'
                        size="medium"
                        style={(isMobile) ? {width: "100%"}: { width: "300px"}}
                        contentAfter={<SearchRegular />}
                        value={searchQuery}
                        onChange={(e, data) => {
                            if (! data) return;

                            setSearchQuery(data.value);
                        }}
                        placeholder="Search teams" />
                </div>
                <div ref={drop} className={(editMode) ? "tab-content tab-content-overlay" : "tab-content"} >
                    {filteredTeams.length === 0
                        ? <TANothingFound size={TANothingFoundSizeMedium} message="No teams found." />
                        : filteredTeams.map((team, index) =>
                            <TATeamCard
                                disabled={disabled}
                                index={index}
                                teamId={team.id}
                                categoryId={categoryId}
                                moveAction={moveAction}
                                removeAction={removeAction}
                                editMode={editMode}
                                copyMode={false} />)}
                </div>
            </div>
            <TAEditCategory
                disabled={disabled}
                editMode={editMode}
                moveAction={moveAction}
				addTeam={addTeam}
                selectedCategory={selectedCategory}
                availableTeams={teams.filter(t => !taCategoryTeams.map(t_ => t_.id).includes(t.id))}
            />
        </>
    );
}
