import { Card, CardHeader, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Text, makeStyles, tokens } from "@fluentui/react-components";

const ScratchIcon = () => {
    return (
        <div style={{width: "48px", height: "48px"}}>
            <svg viewBox="0 0 56 56"><rect width="56" height="56" rx="3" ry="3" fill="#64a2cc"></rect><path fill="#fff" d="M18.53 20.32a4.13 4.13 0 0 1 2.16 2.16 3.86 3.86 0 0 1 0 3.05 4.13 4.13 0 0 1-2.16 2.16 3.86 3.86 0 0 1-3.05 0 4.13 4.13 0 0 1-2.16-2.16 3.86 3.86 0 0 1 0-3.05 4.13 4.13 0 0 1 2.16-2.16 3.86 3.86 0 0 1 3.05 0zM21 39h-2a11.38 11.38 0 0 1-2.1-.2 7.33 7.33 0 0 1-1.87-.6 4 4 0 0 1-1.49-1.2 2.79 2.79 0 0 1-.54-1.71V30h8zM32 38.38a3.11 3.11 0 0 1-.45 1.69 3.37 3.37 0 0 1-1.23 1.15 5.65 5.65 0 0 1-1.57.59A8.08 8.08 0 0 1 27 42a7.69 7.69 0 0 1-1.72-.2 5.8 5.8 0 0 1-1.57-.59 3.4 3.4 0 0 1-1.25-1.15 3.06 3.06 0 0 1-.46-1.68V28h10zM28.52 19.33a4.13 4.13 0 0 1 2.16 2.16 3.84 3.84 0 0 1 0 3 4.13 4.13 0 0 1-2.16 2.16 3.88 3.88 0 0 1-3.05 0 4.13 4.13 0 0 1-2.16-2.17 3.85 3.85 0 0 1 0-3 4.13 4.13 0 0 1 2.16-2.16 3.88 3.88 0 0 1 3.05 0zM38.53 20.32a4.13 4.13 0 0 1 2.16 2.16 3.86 3.86 0 0 1 0 3.05 4.13 4.13 0 0 1-2.16 2.16 3.86 3.86 0 0 1-3.05 0 4.13 4.13 0 0 1-2.16-2.16 3.86 3.86 0 0 1 0-3.05 4.13 4.13 0 0 1 2.16-2.16 3.86 3.86 0 0 1 3.05 0zM41 35.3a2.8 2.8 0 0 1-.54 1.7A4 4 0 0 1 39 38.21a7.59 7.59 0 0 1-1.88.6A11.38 11.38 0 0 1 35 39h-2v-9h8z"></path><path d="M34.14 8.28a.86.86 0 0 1 .26.63v2.69h2.7a.9.9 0 1 1 0 1.8h-2.7v2.7a.9.9 0 0 1-1.81 0v-2.69H29.9a.9.9 0 1 1 0-1.8h2.69V8.92a.9.9 0 0 1 1.55-.63z" fill="#abddd3"></path></svg>
        </div>
    )
}

const TemplateIcon = () => {
    return (
        <div style={{width: "48px", height: "48px"}}>
            <svg viewBox="0 0 56 56"><rect width="56" height="56" rx="3" ry="3" fill="#abddd3"></rect><path fill="#64a2cc"  d="M30 42l-18-3.83V17.83L30 14v28z"></path><path d="M30 19h9.09a1.13 1.13 0 0 1 .91.91v18.18a.87.87 0 0 1-.31.58.9.9 0 0 1-.6.33H30z" fill="#fff"></path><path fill="#64a2cc" d="M48.68 23.9l-4.07-3.63a1.17 1.17 0 0 0-1.16-.2 1 1 0 0 0-.66.87c0 .26.21.58.68 1.06.25.26.67.62 1.27 1.13l.52.42h-3.85a9 9 0 0 0-4.21 1 8.25 8.25 0 0 0-3 2.7A6.7 6.7 0 0 0 33 31a1.08 1.08 0 0 0 2.14 0 5 5 0 0 1 .88-2.8 5.91 5.91 0 0 1 2.25-2 6.67 6.67 0 0 1 3.14-.78h4l-.55.46a16.85 16.85 0 0 0-1.33 1.2c-.63.64-.71.91-.71 1.08a1 1 0 0 0 .66.88 1.16 1.16 0 0 0 1.16-.21l4.07-3.63a.87.87 0 0 0 0-1.34z"></path></svg>
        </div>
    )

}

const useStyles = makeStyles({
    startConfigCard: {
        width: "45%",
        ":hover": {
            cursor: "pointer",
            backgroundColor: tokens.colorNeutralBackground1Hover,
        }
    }
})
export default function TAChooseStartConfigDialog({ data, onScratch, onTemplate}) {
    const classes = useStyles();
    return (
        <Dialog open={data !== null}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle style={{ textAlign: 'center', marginBottom: '20px' }} as="h4">Your organization has a template available.</DialogTitle>
                    <DialogContent>
                        <div style={{display: 'flex', justifyContent: 'space-around' }}>
                            <Card className={classes.startConfigCard} onClick={() => onScratch(data.config)}>
                                <CardHeader
                                    image={<ScratchIcon />}
                                    header={<Text block={true} size={300} weight="bold" >Start from scratch</Text>} />
                                Start from scratch.
                            </Card>
                            <Card className={classes.startConfigCard} onClick={() => onTemplate(data.config, data.orgConfig)}>
                                <CardHeader
                                    image={<TemplateIcon />}
                                    header={<Text block={true} size={300} weight="bold" >Start from organization template</Text>} />
                                Start from the predefined template that was defined for your organization. 
                            </Card>
                        </div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}