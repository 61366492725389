import "./EditCategory.css";
import { Input } from "@fluentui/react-components";
import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-components/unstable';
import { SearchRegular } from "@fluentui/react-icons";
import TATeamCard from "./TeamCard";
import { TANothingFound, TANothingFoundSizeSmall } from "../../../components/NothingFound";
import { useEffect, useState } from "react";

export default function TAEditCategory({
    disabled,
    editMode,
    selectedCategory,
    availableTeams,
    moveAction,
	addTeam
}) {

    const [filteredTeams, setFilteredTeams] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setFilteredTeams(
            availableTeams
                .filter(t => t.name.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    }, [searchQuery, availableTeams])

    if (searchQuery === undefined || searchQuery === null) return;

    return (
        <Drawer
            style={{ width: "35%", height: "100%", alignSelf: 'flex-end' }}
            separator
            // size="small"
            position="right"
            type="inline"
            open={editMode}>
            <DrawerHeader>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <DrawerHeaderTitle>
                        Edit {selectedCategory.name}
                    </DrawerHeaderTitle>
                </div>
                <Input
                    disabled={disabled}
                    style={{ width: '100%' }}
                    type="search"
                    size="medium"
                    contentBefore={<SearchRegular />}
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e, data) => {
                        if (! data) return;

                        setSearchQuery(data.value);
                    }}
                />
            </DrawerHeader>
            <DrawerBody as="div">
                <div className="edit-category-items">
                    {(filteredTeams.length === 0)
                        ? <TANothingFound size={TANothingFoundSizeSmall} message="No teams found." />
                        : filteredTeams.map((team, i) =>
                            <TATeamCard
                                index={i}
                                disabled={disabled}
                                teamId={team.id}
                                categoryId={selectedCategory.id}
                                moveAction={moveAction}
								addTeam={addTeam}
                                copyMode={true}
                                editMode={true} />)
                    }
                </div>
            </DrawerBody>
        </Drawer>
    );
}