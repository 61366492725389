import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import "./index.css";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { Button } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { app } from "@microsoft/teams-js";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Privacy from "./pages/others/Privacy";
import TermsOfUse from "./pages/others/TermsOfUse";
import TAError from "./components/Error";
import Support from "./pages/others/Support";

const container = document.getElementById("root");
const root = createRoot(container);

app.initialize().then(() => {
	app.notifySuccess();
	root.render(
		<SnackbarProvider
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			action={<><Button icon={<DismissRegular />} onClick={() => { closeSnackbar() }}></Button></>}>
			<App />
		</SnackbarProvider>
	);
}).catch((error) => {
	console.error(error)
	return root.render(
		<Router>
			<Routes>
				<Route path="/" element={<TAError />} />
				<Route path="/termsofuse" element={<TermsOfUse />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/support" element={<Support />} />
			</Routes>
		</Router>
	)
});


