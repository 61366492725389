export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new  FileReader();

        fileReader.onloadend = () => {
            const base64String = fileReader.result;
            resolve(base64String)
        }

        fileReader.onerror = () => {
            reject(new Error('Error reading Blob as base64.'))
        }

        fileReader.readAsDataURL(blob);
    });
}

export function dateWithTimeToString(date) {
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date)
}

export function dateToString(date) {
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date)
}
