import "./TeamCard.css"
import { EditRegular, MoreHorizontal20Filled, StarOffRegular, StarRegular } from "@fluentui/react-icons";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Card, CardHeader, Link, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Text } from "@fluentui/react-components";
import { app } from "@microsoft/teams-js";
import { useCallback, useContext, useEffect, useState } from "react";
import { TAConfigContext } from "../../../TeamsNavigator";
import TACustomizeTeam from "./CustomizeTeam";
import TADraggableTeamCard from "./DraggableTeamCard";
import useCheckMobileScreen from "../../../utils/Hooks";

async function goToChannel(url) {
	try {
		const result = await app.openLink(url)
		alert(result)
		
	} catch (error) {
		alert(error)	
	}
}

export default function TATeamCard({
	disabled,
	index,
	teamId,
	categoryId,
	moveAction,
	removeAction,
	editMode,
	copyMode,
	addTeam
}) {
	const { config, setConfig } = useContext(TAConfigContext);
	const [teams, setTeams] = useState([]);
	const [filteredChannels, setFilteredChannels] = useState([]);
	const [customizeMode, setCustomizeMode] = useState(false)
	const [category, setCategory] = useState(null);
	const [taTeam, setTATeam] = useState(null);
	const [team, setTeam] = useState(null);
	const isMobile = useCheckMobileScreen();

	useEffect(() => {
		console.log('[TeamCard] config, categoryId, teams, teamId changed')
		if (!config || !config.taConfig.categories || !config.msTeamsData.teams) return;

		setTeams(config.msTeamsData.teams)

		const category = config.taConfig.categories.find(c => c.id === categoryId);
		const team = config.msTeamsData.teams.find(t => t.id === teamId);

		if (!category || !team) return;

		setCategory(category);
		setTeam(team);

		if (category.teams) {
			const taTeam = category.teams.find(t => t.id === teamId);
			if (!taTeam) return;

			if (!taTeam.channels) {
				taTeam.channels = [];
			}

			// Add the non-hidden channels
			const channels = [];
			for (let channelId of taTeam.channels) {
				const msChannel = team.channels.find(c => c.id === channelId);
				if (!msChannel) continue;
				channels.push(msChannel);
			}

			setTATeam(taTeam);
			setFilteredChannels(channels)
		}

	}, [config, categoryId, teams, teamId]);

	// Save the current config
	const saveConfig = useCallback((newCategories) => {
		console.log("[TeamCard] saving config")
		const newConfig = { ...config, taConfig: { ...config.taConfig, categories: [...newCategories] } }
		setConfig(newConfig);
	}, [config, setConfig]);

	// Add a team to the Favorites category
	const addToFavorites = useCallback((teamId) => {
		console.log('[TeamCard] add to favorites');

		const favoriteCategoryIdx = config.taConfig.categories.findIndex(c => c.isFavoriteCategory);
		if (favoriteCategoryIdx === -1) return;

		const favoriteCategory = config.taConfig.categories[favoriteCategoryIdx];


		favoriteCategory.teams.push({
			id: teamId,
			channels: teams.find(t => t.id === teamId).channels.map(c => c.id)
		});

		config.taConfig.categories[favoriteCategoryIdx] = { ...favoriteCategory }
		saveConfig(config.taConfig.categories);
	}, [config, saveConfig, teams]);

	// Remove a team from the Favorites category
	const removeFromFavorites = useCallback((teamId) => {
		console.log('[TeamCard] removing from favorites', teamId);

		const newConfig = { ...config };
		const favoriteCategoryIdx = config.taConfig.categories.findIndex(c => c.isFavoriteCategory);
		if (favoriteCategoryIdx === -1) return;

		const favoriteCategory = newConfig.taConfig.categories[favoriteCategoryIdx];
		newConfig.taConfig.categories[favoriteCategoryIdx] = {
			...favoriteCategory,
			teams: favoriteCategory.teams.filter(t => t.id !== teamId)
		}

		saveConfig(newConfig.taConfig.categories);
	}, [config, saveConfig])

	// Check if a team is in the Favorites category
	const isInFavorites = useCallback((teamId) => {
		const favoriteCategoryIdx = config.taConfig.categories.findIndex(c => c.isFavoriteCategory);
		if (favoriteCategoryIdx === -1) return false;

		return config.taConfig.categories[favoriteCategoryIdx].teams.map(t => t.id).includes(teamId);
	}, [config]);

	const updateMyChannels = useCallback((categoryId, teamId, channelList) => {
		console.log('[TeamCard] updating MyChannels');

		const newConfig = { ...config };
		const cIdx = newConfig.taConfig.categories.findIndex(c => c.id === categoryId);
		const tIdx = newConfig.taConfig.categories[cIdx].teams.findIndex(t => t.id === teamId);

		newConfig.taConfig.categories[cIdx].teams[tIdx].channels = [...channelList];

		saveConfig(newConfig.taConfig.categories);
	}, [config, saveConfig]);

	if (!team || !category) return <></>

	if (editMode) {
		return <TADraggableTeamCard
			disabled={disabled}
			index={index}
			copyMode={copyMode}
			team={team}
			addTeam={addTeam}
			moveAction={moveAction}
			removeAction={removeAction} />
	}

	if (!taTeam) return <></>

	let cardClasses = "team-card";
	if (disabled) {
		cardClasses += " team-card-disabled";
	}

	return (
		<div className="team-card-container">
			<Card className={cardClasses}>
				<CardHeader
					image={<img height={42} width={42} className="team-card-header-image" src={team.photo} alt="Team Logo" />}
					header={<Text className="ellipsis-text" weight="semibold">{team.name}</Text>}
					action={
						(! isMobile) ?
							<Menu>
								<MenuTrigger disableButtonEnhancement>
									<Button
										disabled={disabled}
										appearance="transparent"
										icon={<MoreHorizontal20Filled />}
										aria-label="More options"
									/>
								</MenuTrigger>
								<MenuPopover>
									<MenuList>
										{
											(!category.isAllTeamsCategory)
												? <MenuItem onClick={() => setCustomizeMode(true)} icon={<EditRegular />}>Customize</MenuItem>
												: <></>
										}
										{
											(category.isFavoriteCategory)
												? <MenuItem onClick={() => removeFromFavorites(teamId)} icon={<StarOffRegular />}>Remove from Favorites</MenuItem>
												: (isInFavorites(teamId))
													? <MenuItem onClick={() => removeFromFavorites(teamId)} icon={<StarOffRegular />}>Remove from Favorites</MenuItem>
													: <MenuItem onClick={() => addToFavorites(teamId)} icon={<StarRegular />}>Add to favorites</MenuItem>
										}
									</MenuList>
								</MenuPopover>
							</Menu>
							: <></>
					}
				/>
				<Accordion collapsible="true" >
					<AccordionItem value="Pinned Channels" disabled={disabled}>
						<AccordionHeader expandIconPosition="end">
							My Channels
						</AccordionHeader>
						<AccordionPanel>
							<TAOverlowCategoryList channels={filteredChannels} />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Card>

			{/* Popup to customize the channels */}
			<TACustomizeTeam
				open={customizeMode}
				team={team}
				taTeam={taTeam}
				category={category}
				setOpen={setCustomizeMode}
				updateMyChannels={updateMyChannels} />
		</div>
	)
}


function min(x, y) {
	if (x <= y) return x;

	return y;
}

function TAOverlowCategoryList({ channels }) {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const maxShowingItems = 5;

	useEffect(() => {
		if (!channels) return;

		if (channels.length > maxShowingItems) {
			setIsOverflowing(true);
		} else {
			setIsOverflowing(false)
		}
	}, [channels])


	return (
		<div className="pinned-channels">
			{(channels.length === 0)
				?
				<div style={{ height: '30px', textAlign: 'center' }}>
					<small>No channels</small>
				</div>
				: null
			}
			{channels.slice(0, min(channels.length, maxShowingItems)).map((channel) => (
				<div style={{ height: '30px' }}
					key={channel.id}
					className="pinned-channels-item"
					onClick={() => goToChannel(channel.webUrl)}>
					<Link as="span">{channel.name}</Link>
				</div>
			))}
			{
				(isOverflowing)
					? <Menu>
						<MenuTrigger disableButtonEnhancement>
							<MenuButton style={{ width: '100%' }} appearance="transparent">+{channels.length - maxShowingItems} channels</MenuButton>
						</MenuTrigger>

						<MenuPopover as="div" style={{ width: '250px', paddingRight: '0', maxHeight: "300px", overflowY: 'auto' }}>
							<MenuList as="div" style={{ paddingRight: '0' }}>
								{channels.slice(maxShowingItems, channels.length).map((channel) =>
									<MenuItem as="div"
										style={{ height: '30px', marginRight: '0', paddingRight: '0' }}
										key={channel.id}
										className="pinned-channels-menuitem"
										onClick={() => goToChannel(channel.webUrl)}>
										<Link as="span">{channel.name}</Link>
									</MenuItem>
								)}
							</MenuList>
						</MenuPopover>
					</Menu>
					: null
			}
		</div>
	);
}
