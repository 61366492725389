import { ArrowClockwise16Regular, ArrowClockwiseRegular } from "@fluentui/react-icons";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, ProgressBar, Tooltip } from "@fluentui/react-components";
import { useContext, useState } from "react";
import { TAConfigContext } from "../../../TeamsNavigator";
import ConfigManager from "../../../utils/ConfigManager";
import { TeamsFxContext } from "../../../utils/Context";
import { createMicrosoftGraphClientWithCredential } from "@microsoft/teamsfx";
import { dateWithTimeToString } from "../../../utils/utils";
import useCheckMobileScreen from "../../../utils/Hooks";


function TARefreshTeamsDialog({ open, value, maxValue }) {
    if (!open) return null;

    return (
        <Dialog open={open}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle style={{ textAlign: 'center', marginBottom: '20px' }} as="h4">Refreshing teams and channels.</DialogTitle>
                    <DialogContent>
                        <Field validationMessage={`Please wait while we're fetching your teams and channels.`} validationState="none">
                            <ProgressBar />
                        </Field>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

async function refreshMSTeamsData(teamsUserCredential, updateProgress, setMaxProgress, config, setConfig) {
    const configManager = new ConfigManager();
    const graph = createMicrosoftGraphClientWithCredential(teamsUserCredential, configManager.scopes);
    const teamsResponse = await configManager.fetchJoinedTeams(graph);

    const nTeams = teamsResponse.value.length;
    setMaxProgress(nTeams);

	const promises = [];
	for (let teamResponseIndex in teamsResponse.value) {
		promises.push(configManager.fetchMSTeamData(graph, teamsResponse.value[teamResponseIndex]))
	}

    let teams = [];
	try {
		const response = await Promise.allSettled(promises)
		for (let resTeam of response) {
			if (resTeam.status === "fulfilled") {
				teams.push(resTeam.value)
			} else {
				console.log(resTeam)
			}
		}
	} catch (error) {
		console.error(error)
	}

    // for (let teamResponseIndex in teamsResponse.value) {
    //     try {
    //         teams.push(await configManager.fetchMSTeamData(graph, teamsResponse.value[teamResponseIndex]))
    //         updateProgress(+teamResponseIndex + 1);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }



    // save config
    const updatedConfig = await configManager.refreshTeamsData(graph, teams, config);
    setConfig(updatedConfig);
}

export default function TARefreshTeamsButton({ size, disabled, hideButton }) {
    const isMobile = useCheckMobileScreen();
    const { teamsUserCredential } = useContext(TeamsFxContext)
    const { config, setConfig } = useContext(TAConfigContext);
    const [isRefreshingTeams, setIsRefresingTeams] = useState(false);
    const [progress, setProgress] = useState(1)
    const [maxProgress, setMaxProgress] = useState(1)

    let icon = <ArrowClockwiseRegular />
    if (size === 'small') {
        icon = <ArrowClockwise16Regular />
    }


    let lastRefreshedString = null;
    if (!config.msTeamsData || !config.msTeamsData.lastUpdated || ! +config.msTeamsData.lastUpdated) {
        lastRefreshedString = ""
    } else {
        const lastRefreshedDate = new Date(+config.msTeamsData.lastUpdated);
        if (lastRefreshedDate) {
            lastRefreshedString = dateWithTimeToString(lastRefreshedDate);
        }
    }

    return (
        <>
            <TARefreshTeamsDialog maxValue={maxProgress} value={progress} open={isRefreshingTeams} />
            {(hideButton)
                ? null
                : <>
                    {
                        (!isMobile)
                            ? <small style={{ marginLeft: "5px", marginRight: "5px" }}>Last Refreshed: {lastRefreshedString}</small>
                            : null
                    }
                    <Tooltip content={`Last refreshed: ${lastRefreshedString}`} relationship="description">
                        <Button
                            style={(isMobile) ? { backgroundColor: 'transparent', border: 'none'} : null}
                            size={size}
                            disabled={isRefreshingTeams || disabled}
                            onClick={async () => {
                                if (!config || !refreshMSTeamsData) return;
                                setIsRefresingTeams(true);
                                await refreshMSTeamsData(teamsUserCredential, setProgress, setMaxProgress, config, setConfig);
                                setIsRefresingTeams(false)
                            }}
                            icon={icon}>
                            {
                                (!isMobile)
                                    ? "Refresh teams"
                                    : null
                            }
                        </Button>
                    </Tooltip>
                </>
            }
        </>
    )
}
