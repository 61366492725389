import "./TeamCard.css"
import { AddRegular, DeleteRegular } from "@fluentui/react-icons";
import { Button, Card, CardHeader, Text } from "@fluentui/react-components";
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";

export default function TADraggableTeamCard({ disabled, index, copyMode, moveAction, team, removeAction, addTeam }) {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop(() => {
        return {
            accept: (copyMode) ? 'null' : 'team',
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId()
                }
            },
            hover(item, monitor) {
                if (!ref.current)
                    return;

                const dragIndex = item.index;
                const hoverIndex = index;

                if (dragIndex === hoverIndex)
                    return;

                moveAction(dragIndex, hoverIndex, item.copyMode);
                item.index = hoverIndex
            }
        }
    }, [copyMode, moveAction])

    // eslint-disable-next-line
    const [{ }, drag] = useDrag({
        type: "team",
        item: () => { return { team: team.id, index, copyMode } },
        canDrag: (!disabled),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    }, [disabled, team]);

    drag(drop(ref));

    let cardClasses = "draggable-team-card";
    if (disabled) {
        cardClasses += " team-card-disabled";
    }

    return (
        <div ref={ref} data-handler-id={handlerId} className="team-card-container" >
            <Card className={cardClasses} style={{ cursor: 'move' }}>
                <CardHeader
                    image={<img height={42} width={42} className="team-card-header-image" src={team.photo} alt="Team Logo" />}
                    header={<Text className="ellipsis-text" weight="semibold">{team.name}</Text>}
                    action={
                        (!copyMode)
                            ? <Button disabled={disabled} onClick={() => removeAction(team)} icon={<DeleteRegular />}></Button>
                            : <Button disabled={disabled} onClick={() => { console.log(team); addTeam(team.id) }} icon={<AddRegular />}></Button>
                            // : <></>
                    }
                />
            </Card>
        </div>
    );
}