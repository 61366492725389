import "./CustomizeTeam.css";
import { Eye20Regular, EyeOff20Regular, Reorder20Filled } from "@fluentui/react-icons";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";

export default function TACustomizeTeam({ open, setOpen, category, team, taTeam, updateMyChannels }) {
    const [myChannels, setMyChannels] = useState([]);
    const [hiddenChannels, setHiddenChannels] = useState([]);
    const inMyChannels = useCallback((channelId) => {
        if (!taTeam && !taTeam.channels) return false;

        return taTeam.channels.includes(channelId);
    }, [taTeam])

    const hideChannel = useCallback((channel, index) => {
        const tmpMyChannels = [...myChannels];
        const tmpHiddenChannels = [...hiddenChannels];
        if (!tmpMyChannels || !tmpHiddenChannels) return;

        if (index < 0 || index >= tmpMyChannels.length) return;
        tmpMyChannels.splice(index, 1);

        tmpHiddenChannels.push({ id: channel.id, name: channel.name })
        setMyChannels(tmpMyChannels);
        setHiddenChannels(tmpHiddenChannels);
    }, [myChannels, hiddenChannels]);

    const showChannel = useCallback((channel, index) => {
        const tmpMyChannels = [...myChannels];
        const tmpHiddenChannels = [...hiddenChannels];
        if (!tmpMyChannels || !tmpHiddenChannels) return;

        tmpMyChannels.push({ id: channel.id, name: channel.name })

        if (index < 0 || index >= tmpHiddenChannels.length) return;
        tmpHiddenChannels.splice(index, 1);

        setMyChannels(tmpMyChannels);
        setHiddenChannels(tmpHiddenChannels);
    }, [myChannels, hiddenChannels]);

    const saveMyChannels = useCallback(() => {
        if (!category || !team || !myChannels) return;
        console.log(myChannels.map(c => c.name))

        updateMyChannels(category.id, team.id, [...myChannels.filter(c => !c.hidden).map(c => c.id)]);
        setOpen(false);
    }, [setOpen, category, myChannels, team, updateMyChannels]);

    const closeCustomizeTeam = useCallback(() => {
        setMyChannels([]);
        setOpen(false);
    }, [setMyChannels, setOpen])

    useEffect(() => {
        if (open) {
            const tmpChannels = taTeam.channels.map(cId => team.channels.find(c => c.id === cId)).map(channel => {
                return {
                    id: channel.id,
                    name: channel.name,
                }
            });
            setMyChannels(tmpChannels);

            const tmpHiddenChannels = team.channels.filter(c => !inMyChannels(c.id)).map((channel) => {
                return ({
                    id: channel.id,
                    name: channel.name
                });
            });
            setHiddenChannels(tmpHiddenChannels);
        }
    }, [open, taTeam, team, inMyChannels])

    if (!category || !team || !team.channels || !taTeam) return <></>


    return (
        <Dialog open={open}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Customize Team: {team.name}</DialogTitle>
                    <DialogContent>
                        <div className="customize-team-body">
                            <div className="customize-team-container">
                                <h4>My channels</h4>
                                { (myChannels.length === 0 )
                                    ? <span>No channels</span>
                                    : null
                                }
                                <ReactSortable className="customize-team-channel-list" list={myChannels} setList={setMyChannels}>
                                    {myChannels.filter(c => !c.hidden).map((channel, index) => {
                                        return (
                                            <p key={channel.id} className="mychannels-item" onClick={() => hideChannel(channel, index)}>
                                                <Reorder20Filled />
                                                <span>{channel.name}</span>
                                                <Eye20Regular />
                                            </p>
                                        );
                                    })}
                                </ReactSortable>
                            </div>
                            <div className="customize-team-hidden-container">
                                <h4>Hidden channels</h4>
                                <div className="customize-team-channel-list">
                                    { (hiddenChannels.length === 0 )
                                        ? <span>No hidden channels</span>
                                        : null
                                    }
                                    {hiddenChannels.map((channel, index) => {
                                        return (
                                            <p key={channel.id} className="mychannels-item item-disabled" onClick={() => showChannel(channel, index)}>
                                                <span>{channel.name}</span>
                                                <EyeOff20Regular />
                                            </p>
                                        );
                                    })}
                                </div>
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={closeCustomizeTeam} appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={saveMyChannels} appearance="primary">Save</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}