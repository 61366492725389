import {
	FluentProvider,
	teamsLightTheme,
	teamsDarkTheme,
	teamsHighContrastTheme,
	tokens,
	Spinner,
} from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import AppConfig from './utils/config'
import { TeamsNavigator } from "./TeamsNavigator";
import { TeamsFxContext } from "./utils/Context";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import TAError from "./components/Error";
import { app } from "@microsoft/teams-js";

export const scopes = ["User.Read", "Team.ReadBasic.All", "Files.ReadWrite.All", "Channel.ReadBasic.All", "Chat.Read", "openid", "profile"];
const authConfig = {
	initiateLoginEndpoint: AppConfig.initiateLoginEndpoint,
	clientId: AppConfig.clientId,
}

export function App() {
	let credential = useMemo(() => new TeamsUserCredential(authConfig), []);
	const [themeString, setThemeString] = useState("");
	const [loading, setLoading] = useState(true);
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const delay = ms => new Promise(
		resolve => setTimeout(resolve, ms)
	);

	app.registerOnThemeChangeHandler((theme) => {
		setThemeString(theme);
	})

	useEffect(() => {
		const init = async () => {
			try {
				// Get theme data
				const context = await app.getContext();
				setThemeString(context.app.theme)

				// Check if user is signed in or needs consent to the permission for the graph API.
				const token = await credential.getToken(scopes);
				if (!token) {
					throw new Error("Invalid token")
				}
				setIsSignedIn(true);
				setError(false)
			} catch (error) {
				try {
					await credential.login(scopes);
					await delay(10000);
					await credential.getToken(scopes);

					setError(false)
					setIsSignedIn(true);
				} catch (error) {
					setError(true)
					setErrorMessage(JSON.stringify(error))
				}
			}
			finally {
				setLoading(false);
			}
		}

		init();
	}, [credential]);

	let content = <></>
	if (error) {
		content = <TAError message={errorMessage} />
	} else {
		content = (!loading && isSignedIn)
			? <TeamsNavigator />
			: <div style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Spinner />
			</div>
	}

	return (
		<TeamsFxContext.Provider value={{ teamsUserCredential: credential, isSignedIn }}>
			<FluentProvider
				theme={
					themeString === "dark"
						? teamsDarkTheme
						: themeString === "contrast"
							? teamsHighContrastTheme
							: {
								...teamsLightTheme,
							}
				}
				style={{ background: tokens.colorNeutralBackground3, display: "flex", flexDirection: 'column', alignItems: 'center', height: '100%' }}
			>
				{content}
			</FluentProvider>
		</TeamsFxContext.Provider>
	);
}