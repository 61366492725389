import {useCallback, useEffect, useState} from "react";

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    
    const handleWindowSizeChange = useCallback(() => {
        setWidth(window.innerWidth);
    }, [setWidth])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [handleWindowSizeChange]);

    return (width <= 768);
}

export default useCheckMobileScreen