import { v4 as uuid } from 'uuid'
import { blobToBase64 } from './utils';

class ConfigManagerError {
	type = "ConfigManagerError";

	constructor(type) {
		this.type = type;
	}
}

export default class ConfigManager {
	CONFIG_VERSION = "0.0.1"
	configFolder = "Opteon.Teams.MyTeamsWorkspace";
	configFile = "config.json";
	scopes = ["User.Read", "Team.ReadBasic.All", "Files.ReadWrite.All", "Channel.ReadBasic.All"];

	constructor() {
		console.log('config manager contstructor')
	}

	validateConfig(config) {
		const taConfig = config.taConfig;
		const msConfig = config.msTeamsData;

		const msTeamsIds = msConfig.teams.map(t => t.id);
		for (let categoryIdx in taConfig.categories) {
			let teamsToBeRemoved = []; // list of indices

			// check if all teams still exist
			for (let teamIdx in taConfig.categories[categoryIdx].teams) {
				const team = taConfig.categories[categoryIdx].teams[teamIdx];
				if (!msTeamsIds.includes(team.id)) {
					teamsToBeRemoved.push(teamIdx);
				}
			}

			if (teamsToBeRemoved.length > 0) {
				console.log(`invalid teams found: ${teamsToBeRemoved}`)
				for (let teamIdx of teamsToBeRemoved) {
					// make the element at that index undefined
					delete taConfig.categories[categoryIdx].teams[teamIdx]
				}

				taConfig.categories[categoryIdx].teams = taConfig.categories[categoryIdx].teams.filter(t => t !== undefined);
			}
		}

		return { ...config, taConfig: taConfig }
	}
	async _checkForOrganizationDefaultConfig(graph) {
		try {
			let driveId = null;
			const drives = await graph.api('/sites/root/drives/').get();
			for (let drive of drives.value) {
				if (drive.name === this.configFolder) {
					driveId = drive.id;
					break;
				}
			}

			if (!driveId) return;

			let itemId = null;
			const items = await graph.api(`/sites/root/drives/${driveId}/search(q='${this.configFile}')`).get();
			for (let item of items.value) {
				if (item.name === this.configFile) {
					itemId = item.id;
					break;
				}
			}

			if (!itemId) return;

			const orgConfig = await graph.api(`/sites/root/drives/${driveId}/items/${itemId}/content`).get()
			return orgConfig;
		} catch (error) {
			console.error(error);
		}

		return null;
	}

	_createNewConfig(teams) {
		return {
			taConfigVersion: this.CONFIG_VERSION,
			taConfig: {
				lastUpdated: Date.now().toString(),
				categories: [
					{
						id: uuid(),
						name: "Favorites",
						isFavoriteCategory: true,
						isAllTeamsCategory: false,
						teams: []
					},
					{
						id: uuid(),
						name: "All teams",
						isFavoriteCategory: false,
						isAllTeamsCategory: true,
						teams: teams.map(team => {
							return {
								id: team.id,
								channels: team.channels.map(channel => channel.id)
							};
						})
					}
				]
			},
			msTeamsData: {
				lastUpdated: Date.now().toString(),
				teams: teams.map(
					team => (
						{
							id: team.id,
							name: team.displayName,
							photo: team.photo,
							channels: team.channels.map(
								c => (
									{
										id: c.id,
										name: c.displayName,
										webUrl: c.webUrl
									}
								)
							)
						}
					)
				)
			}
		}
	}

	async _loadNewTAConfig(graph, updateProgress) {
		console.log('[ConfigManager] loading new config')
		let config = null;
		try {
			const teamsResponse = await graph.api("/me/joinedTeams").get();
			const promises = [];
			for (let teamResponseIndex in teamsResponse.value) {
				promises.push(this.fetchMSTeamData(graph, teamsResponse.value[teamResponseIndex]))
			}

    		let teams = [];
			try {
				const response = await Promise.allSettled(promises)
				for (let resTeam of response) {
					if (resTeam.status === "fulfilled") {
						teams.push(resTeam.value)
					} else {
						console.log(resTeam)
					}
				}
			} catch (error) {
				console.error(error)
			}

			config = this._createNewConfig(teams);
			// Saving config
			await this.saveConfig(graph, config);
		} catch (error) {
			console.error(error);
			throw error;
		}

		return config;
	}

	async _loadTAConfig(graph) {
		let config = null;
		try {
			config = await graph.api(`/me/drive/root:/${this.configFolder}/${this.configFile}:/content`).get();
			if (!config.taConfigVersion || config.taConfigVersion !== this.CONFIG_VERSION) {
				throw new Error("taConfig version mismatch.");
			}
		} catch (error) {
			if (error.message === "Failed to get access token cache silently, please login first: you need login first before get access token.") {
				throw new ConfigManagerError("UnauthorizedError");
			} else if (error.message === "The resource could not be found.") {
				console.error('config not found error')
				throw new ConfigManagerError("ConfigNotFoundError");
			} else if (error.message === "taConfig version mismatch.") {
				throw new ConfigManagerError("ConfigVersionError");
			} else {
				throw error;
			}
		}

		return config;
	}

	mergeOrgConfig(config, orgConfig) {
		const newConfig = { ...config };
		// TODO: Validate both configs

		for (const orgCategory of orgConfig.taConfig.categories) {
			// skip
			if (orgCategory.isFavoriteCategory || orgCategory.isAllTeamsCategory) continue;

			newConfig.taConfig.categories.push({
				...orgCategory,
				teams: orgCategory.teams.filter(t => newConfig.msTeamsData.teams.map(msT => msT.id).includes(t.id))
			})
		}

		return newConfig;
	}

	async refreshTeamsData(graph, msTeams, config) {
		const msTeamsData = {
			lastUpdated: Date.now().toString(),
			teams: msTeams.map(
				team => (
					{
						id: team.id,
						name: team.displayName,
						photo: team.photo,
						channels: team.channels.map(
							c => (
								{
									id: c.id,
									name: c.displayName,
									webUrl: c.webUrl
								}
							)
						)
					}
				)
			)
		}

		const updatedTaConfig = { ...config.taConfig };

		// Update the all teams category
		const allTeamsIdx = updatedTaConfig.categories.findIndex(c => c.isAllTeamsCategory);
		if (!allTeamsIdx) return;

		updatedTaConfig.categories[allTeamsIdx].teams = msTeams.map(team => {
			return {
				id: team.id,
				channels: team.channels.map(channel => channel.id)
			};
		})


		const updatedConfig = { ...config, taConfig: { ...updatedTaConfig }, msTeamsData: { ...msTeamsData } };
		const validatedConfig = this.validateConfig(updatedConfig);

		await this.saveConfig(graph, validatedConfig);
		return validatedConfig;
	}

	async fetchMSTeamData(graph, teamResponse) {
		const team = await graph.api(`teams/${teamResponse.id}`).get()
		const teamPhoto = await graph.api(`teams/${teamResponse.id}/photo/$value`).get()
		const channels = await graph.api(`teams/${teamResponse.id}/channels`).get()

		// convert teams photo blob to a base64 string so we can store it
		const base64String = await blobToBase64(teamPhoto);
		return {
			...team,
			photo: base64String,
			channels: channels.value
		};
	}

	async fetchJoinedTeams(graph) {
		try {
			const teamsResponse = await graph.api("/me/joinedTeams").get();
			return teamsResponse;
		} catch (error) {
			console.error(error)
			return null;
		}
	}

	async loadConfig(graph) {
		console.log('[ConfigManager] loading config')
		let config = null;
		try {
			config = await this._loadTAConfig(graph);
		} catch (error) {
			if (error.type === "ConfigNotFoundError") {
				// Config was not found
				// config = await this._loadNewTAConfig(graph)
				throw error
			} else if (error.type === "UnauthorizedError") {
				throw error;
			} else if (error.type === "ConfigVersionError") {
				console.error('config version outdated')
				// TODO: This needs to transform old config to new config.
				// now it just loads the new default one.
				config = await this._loadNewTAConfig(graph)
			} else {
				console.error(error)
				// TODO: This needs to transform old config to new config.
				// now it just loads the new default one.
				config = await this._loadNewTAConfig(graph)
				// throw error
			}
		}

		return config;
	}

	async saveConfig(graph, config) {
		try {
			await graph.api(`/me/drive/root:/${this.configFolder}/${encodeURIComponent(this.configFile)}:/content`).put(config);
		} catch (error) {
			console.error("[ConfigManager] - ", error, config);
			throw new ConfigManagerError("ConfigNotSavedError");
		}
	}

}