import "./Dashboard.css";
import { Button, Tab, Divider, TabList, tokens, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, MenuButton, Dialog, DialogTrigger, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Skeleton, SkeletonItem, Input, Tooltip } from "@fluentui/react-components";
import { v4 as uuid } from 'uuid'
import { AddRegular, DeleteRegular, Dismiss12Regular, MoreHorizontal20Regular } from "@fluentui/react-icons";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import TACategory from "./components/Category";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TAConfigContext } from "../../TeamsNavigator";
import { ReactSortable } from "react-sortablejs";
import useCheckMobileScreen from "../../utils/Hooks";

function Dashboard({ loading }) {
	const isMobile = useCheckMobileScreen();
	const { config, setConfig } = useContext(TAConfigContext);
	const [editMode, setEditMode] = useState(false);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [showRemoveDialog, setShowRemoveDialog] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);

	useEffect(() => {
		if (!config || loading) return;

		setCategories(config.taConfig.categories);
	}, [config, loading])

	const saveConfig = useCallback((newCategories) => {
		console.log('[Dashboard] saving config')
		setConfig({ ...config, taConfig: { ...config.taConfig, categories: [...newCategories] } });
	}, [config, setConfig])

	const addCategory = useCallback((categoryName) => {
		if (!categoryName) return;

		const newCategory = {
			id: uuid(),
			name: categoryName,
			isFavoriteCategory: false,
			isAllTeamsCategory: false,
			teams: []
		};

		const tmpCategories = [...categories, newCategory];

		setCategories(tmpCategories)
		setSelectedCategory(newCategory)
		setEditMode(true);

		saveConfig(tmpCategories);
	}, [setCategories, categories, setSelectedCategory, saveConfig]);

	const removeCategory = useCallback(() => {
		console.log('remove category')
		setShowRemoveDialog(false);
		setEditMode(false);

		const tmpCategories = [...categories]
		const idx = categories.findIndex(c => c.id === selectedCategory.id);
		tmpCategories.splice(idx, 1);

		setCategories(tmpCategories)

		// Select first category as selected by default.
		setSelectedCategory(tmpCategories[0])

		saveConfig(tmpCategories);
	}, [saveConfig, categories, setCategories, selectedCategory, setShowRemoveDialog, setEditMode, setSelectedCategory])

	const isLoading = loading || !config || !selectedCategory
	if (config && !loading && !selectedCategory) {
		// Select first category as selected by default.
		setSelectedCategory(config.taConfig.categories[0]);
	}

	const sidepanel = useMemo(() => {
		console.log("side panel changed")
		if (isMobile) {
			if (dialogOpen) {
				return (
					<div className="category-list" style={{ backgroundColor: tokens.colorNeutralBackground4 }} >
						<div className="category-list-header">
							<h2>Categories</h2>
							<Tooltip content="Close" relationship="description">
								<Button disabled={isLoading || editMode} onClick={() => setDialogOpen(!dialogOpen)} appearance="transparent" icon={<Dismiss12Regular />} />
							</Tooltip>
						</div>
						{
							(isLoading)
								? <Skeleton
									className="category-tab-list"
									appearance="translucent">
									{[1, 2, 3, 4, 5].map(() =>
										<div style={{ width: '285px', display: 'flex', alignItems: 'center', paddingTop: '15px' }}>
											<SkeletonItem size={24} />
										</div>
									)}
								</Skeleton>
								: <TACategoryTabList
									saveConfig={saveConfig}
									selectedCategory={selectedCategory}
									editMode={editMode}
									categories={categories}
									setSelectedCategory={(category) => {
										setSelectedCategory(category);
										if (isMobile) {
											setDialogOpen(false)
										}
									}}
									setShowRemoveDialog={setShowRemoveDialog} />
						}
					</div>
				)
			} else {
				return null;
			}
		} else {
			return <div className="category-list" style={{ backgroundColor: tokens.colorNeutralBackground4 }} >
				<div className="category-list-header">
					<h2>Categories</h2>
				</div>
				{
					(isLoading)
						? <Skeleton
							className="category-tab-list"
							appearance="translucent">
							{[1, 2, 3, 4, 5].map(() =>
								<div style={{ width: '285px', display: 'flex', alignItems: 'center', paddingTop: '15px' }}>
									<SkeletonItem size={24} />
								</div>
							)}
						</Skeleton>
						: <TACategoryTabList
							saveConfig={saveConfig}
							selectedCategory={selectedCategory}
							editMode={editMode}
							categories={categories}
							setSelectedCategory={setSelectedCategory}
							setShowRemoveDialog={setShowRemoveDialog} />
				}
				<div className="category-list-footer">
					<Divider />
					<div className="category-action-container">
						<TAAddCategoryDialog
							disabled={editMode || isLoading}
							addCategory={addCategory} />
					</div>
					{/* <small>v 0.0.1</small> */}
				</div>
			</div>
		}
	}, [categories, addCategory, dialogOpen, editMode, isLoading, isMobile, saveConfig, selectedCategory]);

	return (
		<div className={(isMobile) ? "mobile dashboard-page" : "dashboard-page"}>
			<div className="dashboard-body">
				{sidepanel}
				<div className="category-container" style={{ backgroundColor: tokens.colorNeutralBackground2 }}>
					<DndProvider backend={HTML5Backend}>
						<TACategory
							disabled={isLoading}
							isLoading={isLoading}
							categoryId={selectedCategory?.id}
							setEditMode={setEditMode}
							setDialogOpen={setDialogOpen}
							editMode={editMode} />
					</DndProvider>
				</div>
			</div>
			<TARemoveDialog onConfirm={removeCategory} show={showRemoveDialog} onCancel={() => setShowRemoveDialog(false)} />
			{/* <div style={{height: '35px', width: '100%', textAlign: 'center', padding: "0px 10px", display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: tokens.colorPaletteRedBackground3, color: 'white'}}>
                <span>This is a trial version of <b>My Teams Workspace</b>. Expiry date: { dateToString(new Date('12/01/2024'))}</span>
            </div> */}
		</div>
	);
}

function isSameCategoryList(cl1, cl2) {
	let isEqual = true;
	if (cl1.length !== cl2.length) return false;

	let i = 0;
	for (let c1 of cl1) {
		if (c1.id !== cl2[i].id) {
			isEqual = false;
			break;
		}

		i += 1;
	}

	if (isEqual)
		console.log('Categories are the same')
	return isEqual;
}

function isSameCategoryListTeams(cl1, cl2) {
	let i = 0;
	let hasEqualTeams = true;
	for (let c1 of cl1) {
		if (!categoryHasEqualTeams(c1, cl2[i])) {
			hasEqualTeams = false;
			break;
		}

		i += 1;
	}

	return hasEqualTeams;
}

function categoryHasEqualTeams(c1, c2) {
	let isEqual = true;
	if (c1.teams.length !== c2.teams.length) {
		return false;
	}

	let i = 0;
	for (let team of c1.teams) {
		if (team.id !== c2.teams[i].id) {
			isEqual = false;
			break;
		}

		i += 1
	}

	return isEqual;
}

function TACategoryTabList({ saveConfig, selectedCategory, editMode, categories, setSelectedCategory, setShowRemoveDialog }) {
	const [reorderList, setReorderList] = useState([...categories]);
	const [shouldSave, setShouldSave] = useState(false);

	const saveNewCategoryOrder = useCallback(() => {
		// Order changed
		if (reorderList.length > 0 && !isSameCategoryList(categories, reorderList)) {
			console.log('[TACategoryTabList] order changed - Save config')
			saveConfig([...reorderList]);
		}
	}, [reorderList, saveConfig, categories]);

	useEffect(() => {
		if (categories && (reorderList.length === 0 || categories.length !== reorderList.length)) {
			// Cases:
			// 1. Reorder list is empty and is being filled
			// 2. Add category
			// 3. Remove category
			console.log('[TACategoryTabList] reorder list updated', reorderList, [...categories])
			setReorderList([...categories]);
		} else if (!isSameCategoryList(reorderList, categories) && shouldSave) {
			// Case: category order changed
			console.log('[TACategoryTabList] reorder categories updated', reorderList, categories)
			saveNewCategoryOrder();
			setShouldSave(false);
		} else if (isSameCategoryList(reorderList, categories)) {
			if (!isSameCategoryListTeams(reorderList, categories)) {
				console.log('[TACategoryTabList] teams need to be updated', reorderList, categories)
				setReorderList([...categories])
			}
		}
	}, [saveNewCategoryOrder, categories, reorderList, shouldSave]);

	return (
		<TabList
			className="category-tab-list"
			vertical="true"
			appearance="subtle"
			size="medium"
			selectedValue={selectedCategory.id} >
			<ReactSortable className="category-tab-list" list={reorderList} setList={(newState) => { setShouldSave(true); setReorderList(newState); }}>
				{reorderList.map(category => {
					return (
						<Tab as="div"
							className="category-tab"
							onContextMenu={(e) => {
								if (editMode) return;

								e.preventDefault();
								const btn = document.getElementById(`category-more-menu-${category.id}`);
								if (btn)
									btn.click()
							}}
							onClick={(e) => {
								if (editMode) return;

								e.preventDefault();
								setSelectedCategory(categories.find(c => c.id === category.id))
							}}
							disabled={(editMode && category.id !== selectedCategory.id)}
							value={category.id}
							key={category.id}>
							<div
								style={{ width: '285px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div
									style={{ flex: 1, height: "100%" }}>
									{category.name}
								</div>
								<Menu>
									<MenuTrigger>
										<MenuButton
											id={`category-more-menu-${category.id}`}
											className="category-more-menu"
											appearance="transparent" icon={<MoreHorizontal20Regular size="large" />} />
									</MenuTrigger>
									<MenuPopover>
										<MenuList>
											<MenuItem onClick={() => { setShowRemoveDialog(true); }} disabled={category.isFavoriteCategory || category.isAllTeamsCategory} icon={<DeleteRegular />}>
												Remove category
											</MenuItem>
										</MenuList>
									</MenuPopover>
								</Menu>
							</div>
						</Tab>
					)
				})}
			</ReactSortable>
		</TabList>
	);
}

function TARemoveDialog({ onConfirm, show, onCancel }) {
	return (
		<Dialog open={show}>
			<DialogSurface>
				<DialogBody>
					<DialogTitle>Remove category</DialogTitle>
					<DialogContent>
						Are you sure you want to remove this category?
					</DialogContent>
					<DialogActions>
						<DialogTrigger disableButtonEnhancement>
							<Button onClick={onCancel} appearance="secondary">Close</Button>
						</DialogTrigger>
						<DialogTrigger disableButtonEnhancement>
							<Button onClick={onConfirm} appearance="primary">Remove</Button>
						</DialogTrigger>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
}

function TAAddCategoryDialog({ addCategory, disabled }) {
	const [open, setOpen] = useState(false);
	const [categoryName, setCategoryName] = useState("");

	const close = useCallback(() => {
		setCategoryName("");
		setOpen(false);
	}, [])

	const createNewCategory = useCallback(() => {
		if (!categoryName || categoryName.length === 0) return;

		addCategory(categoryName);
		close();
	}, [addCategory, categoryName, close]);

	return (
		<Dialog open={open}>
			<Button
				disabled={disabled}
				className="add-category-btn"
				appearance="subtle"
				onClick={() => setOpen(true)}
				icon={<AddRegular />} >Add Category</Button>
			<DialogSurface>
				<form onSubmit={createNewCategory}>
					<DialogBody>
						<DialogTitle>Add a new category</DialogTitle>
						<DialogContent>
							<Input
								style={{ width: '100%' }}
								required={true}
								placeholder="Enter a category name"
								value={categoryName}
								onChange={(_, data) => {
									const name = data.value;
									if (!name) return;

									setCategoryName(name);
								}} />
						</DialogContent>
						<DialogActions>
							<Button appearance="secondary" onClick={close}>Cancel</Button>
							<Button appearance="primary" type="submit">Create</Button>
						</DialogActions>
					</DialogBody>
				</form>
			</DialogSurface>
		</Dialog>
	);
}

export { Dashboard }