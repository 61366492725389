import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Privacy from "./pages/others/Privacy";
import TermsOfUse from "./pages/others/TermsOfUse";
import { createMicrosoftGraphClientWithCredential } from "@microsoft/teamsfx";
import TAError from "./components/Error";
import { Dashboard } from "./pages/dashboard/Dashboard";
import ConfigManager from "./utils/ConfigManager"
import { useMemo } from "react";
import { useSnackbar } from "notistack";
import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, Field, ProgressBar } from "@fluentui/react-components";
import { TeamsFxContext } from "./utils/Context";
import TAChooseStartConfigDialog from "./pages/dashboard/components/ChooseStartConfigDialog";
import { scopes } from "./App";

const TAConfigContext = createContext(null);

function TeamsNavigator() {
	const { teamsUserCredential, isSignedIn } = useContext(TeamsFxContext);
	const configManager = useMemo(() => new ConfigManager(), []);
	const { enqueueSnackbar } = useSnackbar();
	const [config, setConfig] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [loadingNewConfigProgress, setLoadingNewConfigProgress] = useState(0);
	const [isLoadingNewConfig, setIsLoadingNewConfig] = useState(false);
	const [chooseStartConfig, setChooseStartConfig] = useState(null);


	const loadConfigOrCreateConfig = useCallback(async (graph) => {
		let config = null;
		try {
			config = await configManager.loadConfig(graph);
			config = configManager.validateConfig(config);

			setConfig(config)
		} catch (error) {
			if (error.type === "ConfigNotFoundError") {
				// Open loading popup
				setChooseStartConfig(null);
				setIsLoadingNewConfig(true)
				let orgConfig = null;
				try {
					config = await configManager._loadNewTAConfig(graph, setLoadingNewConfigProgress);
					orgConfig = await configManager._checkForOrganizationDefaultConfig(graph)
				} catch (error) {
					enqueueSnackbar("Failed to load configuration file. Please try again in a minute.", { variant: 'error' })
					throw error;
				} finally {
					setIsLoadingNewConfig(false);
				}

				// TODO: Implement orgConfig validation
				if (!orgConfig) {
					setConfig(config);
					return;
				}

				setChooseStartConfig({ config, orgConfig });
			} else {
				throw error;
			}
		}

	}, [configManager, setConfig, setChooseStartConfig, setIsLoadingNewConfig, enqueueSnackbar])


	useEffect(() => {
		async function loadConfig() {
			if (!teamsUserCredential || !isSignedIn) return;

			const graph = createMicrosoftGraphClientWithCredential(teamsUserCredential, scopes);
			if (!graph) return;

			try {
				await loadConfigOrCreateConfig(graph);
			} catch (error) {
				if (error.type === "UnauthorizedError") {
					// await teamsUserCredential.login(scope);
					// await loadConfigOrCreateConfig(graph);
					enqueueSnackbar("Failed to load configuration file. Please try again in a minute.", { variant: 'error' })
				} else {
					enqueueSnackbar("Failed to load configuration file. Please try again in a minute.", { variant: 'error' })
					throw error;
				}

				setError(error);
			} finally {
				setLoading(false);
			}
		}

		loadConfig();
	}, [teamsUserCredential, isSignedIn, loadConfigOrCreateConfig, enqueueSnackbar])

	const saveConfig = useCallback(async (config) => {
		console.log('[App] saving config', config);
		try {
			const graph = createMicrosoftGraphClientWithCredential(teamsUserCredential, scopes);
			await configManager.saveConfig(graph, config);
			setConfig(config);
		} catch (error) {
			console.error(error)
			enqueueSnackbar("Failed to save config!", { variant: 'error' });
		}
	}, [teamsUserCredential, setConfig, configManager, enqueueSnackbar]);

	const configFromScratch = useCallback((config) => {
		setConfig(config);
		saveConfig(config);
		setChooseStartConfig(null);
	}, [saveConfig]);

	const configFromTemplate = useCallback((config, orgConfig) => {
		const newConfig = configManager.mergeOrgConfig(config, orgConfig)
		setConfig(newConfig);
		saveConfig(newConfig)
		setChooseStartConfig(null);
	}, [configManager, saveConfig]);

	if (error && !loading) {
		return <TAError message={JSON.stringify(error)} />
	}

	return (
		<TAConfigContext.Provider value={{ config, setConfig: saveConfig }}>
			<Router>
				<Routes>
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/termsofuse" element={<TermsOfUse />} />
					<Route path="/tab" element={<Dashboard loading={loading} />} />
					<Route path="/blank-auth-end.html" element={<span>Test</span>} />
					<Route path="*" element={<Navigate to={"/tab"} />}></Route>
				</Routes>
			</Router>
			<TALoadNewConfigDialog open={isLoadingNewConfig} progress={loadingNewConfigProgress} />
			<TAChooseStartConfigDialog data={chooseStartConfig} onScratch={configFromScratch} onTemplate={configFromTemplate} />
		</TAConfigContext.Provider>
	);
}


function TALoadNewConfigDialog({ open, progress }) {
	return (
		<Dialog open={open}>
			<DialogSurface>
				<DialogBody>
					<DialogTitle style={{ textAlign: 'center', marginBottom: '20px' }} as="h4">Loading teams and channels.</DialogTitle>
					<DialogContent>
						<Field validationMessage={`Please wait while we're fetching your teams and channels.`} validationState="none">
							<ProgressBar />
						</Field>
					</DialogContent>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
}

export { TeamsNavigator, TAConfigContext };